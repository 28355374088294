import { Fragment, useEffect, useState } from 'react';
import { Heading3, Para } from '../../../../components/atoms/typography/typography';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { PrimaryButton } from '../../../../components/molecules/buttons/buttons';
import TagInput from '../../../../components/molecules/tag-input/tagInput';
import VoiceWoman from './../../../../assets/women-img.jpg';
import Woman2 from './../../../../assets/women-2.jpg';
import { useDispatch } from 'react-redux';
import {
  createMsmeCampaignAction,
  getModelConfigurationsAction,
  sampleAudioDataAction
} from '../../../../redux/states/msme/msme.actions';
import { useParams } from 'react-router-dom';
import SelectInput from '../../../../components/organism/select-input/selectInput';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import { ButtonType, LoaderType } from '../../../../models/ui.enum';
import { InfoModal } from '../../../../components/molecules/modal/modal';
import { PlayCircle, WarningCircle } from 'phosphor-react';
import {
  base64toBlob,
  replaceDoubleQuotesWithSingleQuotes,
  showAlert
} from '../../../../utils/utils';

export default function CreateCampaign() {
  const dispatch: any = useDispatch();
  const [name, setName] = useState<string>('');
  const [conversationFlow, setConversationFlow] = useState<string>('');
  const [greetingMessage, setGreetingMessage] = useState<string>('');
  const [extractionQuery, setExtractionQuery] = useState<string>('');
  const [instruction, setInstruction] = useState<string>('');
  const [summaryQuery, setSummaryQuery] = useState<string>('');
  const [dailyLimit, setDailyLimit] = useState<string>('');
  const [gptFunctions, setGptFunctions] = useState<any>('[]');
  const [type, setType] = useState<string>('INCOMING');
  const [endCallRegex, setEndCallRegex] = useState<any>();
  const [languages, setLanguages] = useState<any>();
  const [attributesErrorMsg, setAttributesErrorMsg] = useState('');
  const [hint, setHint] = useState('');
  const [llmModelVendor, setLlmModelVendor] = useState<string>('');
  const [llmModelModel, setLLMModelModel] = useState<string>('');
  const [selectedTTSModelOption, setSelectedTTSModelOption] = useState('');
  const [sttModelVendor, setSttModelVendor] = useState<string>('');
  const [sttModelModel, setSttModelModel] = useState<string>('');
  const [sttLanguage, setSttLanguage] = useState<string>('');
  const [sttEndpointing, setSttEndpointing] = useState<string>('500');
  const [translationLlmVendor, setTranslationLlmVendor] = useState<string>('');
  const [translationLlmModel, setTranslationLlmModel] = useState<string>('');
  const [translationLlmRegion, setTranslationLlmRegion] = useState<string>('');
  const [translationLlmLang, setTranslationLlmLang] = useState<string>('');
  const [ttsVendor, setTtsVendor] = useState<string>('');
  const [ssmlConfigsSpeed, setSsmlConfigsSpeed] = useState<string>('1');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [llmRegion, setLlmRegion] = useState<string>('');
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any[]>([]);
  const [values, setValues] = useState<any>();
  const [pauseSamplePlayBtn, setPauseSamplePlayBtn] = useState<boolean>(false);
  const [speechText, setSpeeechText] = useState<string>('');
  const [showMeta, setShowMeta] = useState(false);
  const [gptFunctionData, setGptFunctionData] = useState([]);

  const { data: modelConfigurations, loading: loading } = useSelector(
    (state: any) => state?.msme?.modelConfigurations
  );

  async function configurations() {
    const res = await dispatch(getModelConfigurationsAction());
    setValues(res);
  }

  useEffect(() => {
    configurations();
  }, []);

  const handleInputChange = (index: any, key: any, value: any) => {
    const newMetadata: any = [...metadata];
    newMetadata[index][key] = value;
    setMetadata(newMetadata);
  };

  const { msmeId } = useParams();

  const handleAddField = () => {
    setShowMeta(true);
    setMetadata([...metadata, { label: '', value: '' }]);
  };

  const handleRemoveField = (index: number) => {
    const newMetadata = [...metadata];
    newMetadata.splice(index, 1);
    setMetadata(newMetadata);
  };

  function compareArrays(arr1: string[], arr2: string[]): boolean {
    return arr1.every((element) => arr2.includes(element));
  }

  function checkInstructionAndMetaDataconsistency() {
    let metaFields = metadata;
    if (metadata.length == 1 && metadata[0].label.length == 0) {
      metaFields = [];
    }
    const regex = /userMetaData\['([^']+)'\]/g;
    const matches = [];
    let match;

    let ins = replaceDoubleQuotesWithSingleQuotes(instruction);

    while ((match = regex.exec(ins)) !== null) {
      matches.push(match[1]);
    }

    const uniqueSet = new Set(matches);
    const uniqueArray = Array.from(uniqueSet);

    let metaDataFields: any = [];
    metaFields.map((i: any) => {
      metaDataFields.push(i.label);
    });

    let consistentMetaAndInstructions = compareArrays(uniqueArray, metaDataFields);
    return consistentMetaAndInstructions;
  }

  async function sendCampaignData() {
    if (llmModelVendor === '' || llmModelVendor === 'Select from options') {
      showAlert(dispatch, 'Please select Core layer of Intelligence layer');
      return;
    } else if (llmModelModel === '' || llmModelModel === 'Select from options') {
      showAlert(dispatch, 'Please select Model of Intelligence layer');
      return;
    } else if (llmModelVendor === 'microsoft') {
      if (llmRegion === '' || llmRegion === 'Select from options') {
        showAlert(dispatch, 'Please select Region of Intelligence layer');
        return;
      }
    }

    if (ttsVendor === '' || ttsVendor === 'Select from options') {
      showAlert(dispatch, 'Please select Vendor of Speaker Persona');
      return;
    } else if (selectedTTSModelOption === '' || selectedTTSModelOption === 'Select from options') {
      showAlert(dispatch, 'Please select Model of Speaker Persona');
      return;
    }

    if (sttModelVendor === '' || sttModelVendor === 'Select from options') {
      showAlert(dispatch, 'Please select Vendor of Speech Recognition');
      return;
    } else if (sttModelModel === '' || sttModelModel === 'Select from options') {
      showAlert(dispatch, 'Please select Model of Speech Recognition');
      return;
    } else if (sttLanguage === '' || sttLanguage === 'Select from options') {
      showAlert(dispatch, 'Please select Language of Speech Recognition');
      return;
    }

    let checkConsistency = checkInstructionAndMetaDataconsistency();
    const metaDataEmptyValue = metadata.some((field) => field.label && !field.value);
    if (metadata.length == 1 && metadata[0].label.length == 0) {
      setMetadata([]);
    }
    if (endCallRegex.length == 0) {
      showAlert(dispatch, 'End Call regex should not be empty');
    } else if (type != 'OUTGOING' && (mobileNumber.length < 10 || mobileNumber.length > 10)) {
      showAlert(dispatch, 'Mobile Number should be of 10 digits');
    } else if (languages.length == 0) {
      showAlert(dispatch, 'Languages should not be empty');
    } else if (metaDataEmptyValue) {
      showAlert(dispatch, 'Value of metadata labels cannot be empty');
    } else if (!checkConsistency) {
      showAlert(dispatch, 'Ensure consistency in Instuction and MetaData fields');
    } else {
      setLoadings(true);
      dispatch({ type: LOADING_ON });
      let maxDailyLimit = parseInt(dailyLimit, 10);
      let endpointing = parseInt(sttEndpointing, 10);
      let finalEndCallRegex: any = [];
      let finalLanguages: any = [];
      endCallRegex.map((i: any) => {
        finalEndCallRegex.push(i.attribute);
      });
      languages.map((i: any) => {
        finalLanguages.push(i.attribute);
      });
      if (gptFunctions) {
        try {
          const parsedGptFunctions = JSON.parse(gptFunctions);
          setGptFunctionData(parsedGptFunctions);
        } catch (error) {
          showAlert(dispatch, 'GPT Function format is incorrect');
          setLoadings(false);
          return;
        }
      }

      const requestBody: any = {
        msmeId: msmeId,
        name: name,
        llmModel: {
          vendor: llmModelVendor,
          model: llmModelModel
        },
        ttsModel: {
          vendor: ttsVendor,
          model: selectedTTSModelOption,
          ssmlConfigs: {
            speed: ssmlConfigsSpeed
          }
        },
        sttModel: {
          vendor: sttModelVendor,
          model: sttModelModel,
          language: sttLanguage,
          endpointing: endpointing
        },
        conversationFlow: conversationFlow,
        greetingMessage: greetingMessage,
        endCallRegex: finalEndCallRegex,
        extractionQuery: extractionQuery,
        instruction: replaceDoubleQuotesWithSingleQuotes(instruction),
        summaryQuery: summaryQuery,
        translationLLMModel: {
          vendor: translationLlmVendor,
          model: translationLlmModel,
          region: translationLlmRegion,
          lang: translationLlmLang
        },
        type: type,
        active: true,
        maxDailyLimit: maxDailyLimit,
        language: finalLanguages,
        gptFunctions: gptFunctionData,
        userMetaData: metadata.length == 1 && metadata[0].label.length == 0 ? [] : metadata
      };
      if (type != 'OUTGOING') requestBody.mobileNumber = mobileNumber;
      if (
        selectedTTSModelOption ==
        values?.data?.data?.ttsModel.find((item: any) => item.vendor == ttsVendor)?.model[1]
      )
        requestBody.ttsModel.deploymentId = '7c638e13-8ebe-4b2f-920a-bcf2527d8d77';
      if (llmModelVendor == 'microsoft') requestBody.llmModel.region = llmRegion;
      const res = await dispatch(createMsmeCampaignAction(requestBody));
      if (res.status == 200) {
        setLoadings(false);
        dispatch({ type: LOADING_OFF });
        toast.success('Campaign Created');
        navigate(-1);
      } else {
        setLoadings(false);
        dispatch({ type: LOADING_OFF });
        showAlert(dispatch, res?.message);
      }
    }
  }

  async function handlePLaySampleAudio() {
    setPauseSamplePlayBtn(true);
    const data: any = {
      ttsModel: {
        vendor: ttsVendor,
        model: selectedTTSModelOption,
        ssmlConfigs: {
          speed: ssmlConfigsSpeed
        }
      },
      speechText: speechText
    };

    if (selectedTTSModelOption == 'Kritika Custom Voice (en-IN)Neural')
      data.ttsModel.deploymentId = '7c638e13-8ebe-4b2f-920a-bcf2527d8d77';
    try {
      const res = await dispatch(sampleAudioDataAction(data));
      const file = base64toBlob(res?.data?.data?.data, 'audio/mp3');
      const audioFile = URL.createObjectURL(file);
      const audio = new Audio();
      audio.src = audioFile;
      audio.play();
    } catch (e) {
      console.log(e);
    }
    setPauseSamplePlayBtn(false);
  }

  const handleOptionChange = (event: any) => {
    setSelectedTTSModelOption(event.target.value);
  };

  function typeHandler(e: any) {
    setType(e.target.value);
  }

  function handleEnterKeyPress(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <Fragment>
      <form
        onSubmit={(e: any) => {
          e?.preventDefault();
          sendCampaignData();
        }}
        onKeyPress={handleEnterKeyPress}
      >
        <div className="row">
          <div className="col-lg-6 m-auto">
            {loading ? (
              <SkeletonLoader cardType={LoaderType.TILE} numTiles={6} />
            ) : (
              <div className={`edit-card mt-5 p-4`}>
                <PrimaryTextfield
                  onChange={(value) => setName(value)}
                  label="Name"
                  placeholder="Enter Campaign name"
                  value={name}
                  fieldName="name"
                  type="text"
                  required
                />
                <Para className="mb-2">Intelligence Layer :</Para>

                <div className="border rounded p-4 mb-4">
                  <SelectInput
                    labelText="Core Layer"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.llmModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      setLlmModelVendor(e);
                      setLLMModelModel('');
                      setLlmRegion('');
                    }}
                    // defaultValue={0}
                    required
                  ></SelectInput>

                  {llmModelVendor == 'microsoft' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmModelVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModelModel(e);
                      }}
                      required
                      // defaultValue={0}
                    ></SelectInput>
                  )}

                  {llmModelVendor == 'openAI' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmModelVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModelModel(e);
                      }}
                      // defaultValue={0}
                      required
                    ></SelectInput>
                  )}

                  {llmModelVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmModelVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModelModel(e);
                      }}
                      // defaultValue={0}
                      required
                    ></SelectInput>
                  )}

                  {llmModelVendor == 'microsoft' && (
                    <SelectInput
                      labelText="Region"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmModelVendor
                              )?.region
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLlmRegion(e);
                      }}
                      defaultValue={0}
                      required
                    ></SelectInput>
                  )}
                </div>
                <Para className="mb-2">Speaker Persona :</Para>

                <div className="mb-4 p-4 border rounded">
                  <SelectInput
                    labelText="Vendor"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.ttsModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      setTtsVendor(e);
                      setSelectedTTSModelOption('');
                    }}
                    defaultValue={0}
                    required
                  ></SelectInput>

                  {ttsVendor == 'Voizo proprietary' && (
                    <div className="row mb-4">
                      <div className="col-sm-3">
                        <label className="w-100">
                          <input
                            type="radio"
                            name="test"
                            value="hi-IN-SwaraNeural"
                            checked={
                              selectedTTSModelOption ===
                              values?.data?.data?.ttsModel.map((item: any) => item.model)?.[0][0]
                            }
                            onChange={handleOptionChange}
                          />
                          <div className="content-container">
                            <div className="img-box">
                              <img src={VoiceWoman} alt="Option 1" className="man-img" />
                            </div>
                            <Heading3>Swara</Heading3>
                            {/* <Play className="overlay" size={16} weight="fill" onClick={() => {}} /> */}
                          </div>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="w-100">
                          <input
                            type="radio"
                            name="test"
                            value="Kritika Custom Voice (en-IN)Neural"
                            checked={
                              selectedTTSModelOption ===
                              values?.data?.data?.ttsModel.map((item: any) => item.model)?.[0][1]
                            }
                            onChange={handleOptionChange}
                          />
                          <div className="content-container mt-sm-0 mt-3">
                            <div className="img-box">
                              <img src={Woman2} alt="Option 2" className="man-img" />
                            </div>
                            <Heading3>Kritika</Heading3>
                            {/* <Play className={`overlay`} size={16} weight="fill" onClick={() => {}} /> */}
                          </div>
                        </label>
                      </div>
                    </div>
                  )}

                  {ttsVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.ttsModel?.find(
                                (item: any) => item.vendor[0] === ttsVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSelectedTTSModelOption(e);
                      }}
                      defaultValue={0}
                      required
                    ></SelectInput>
                  )}

                  <label htmlFor="customRange3" className="form-label">
                    SSML Configuration Speed : {ssmlConfigsSpeed}
                  </label>
                  <input
                    type="range"
                    onChange={(e) => {
                      setSsmlConfigsSpeed(e.target.value);
                    }}
                    value={ssmlConfigsSpeed}
                    className="form-range"
                    min="0.5"
                    max="2"
                    step="0.1"
                    id="ssmlConfigSpeedRange"
                    required
                  ></input>

                  <Para className="mb-2">Sample Speech Text :</Para>
                  <textarea
                    className="text-area mb-4 w-100"
                    rows={10}
                    value={speechText}
                    maxLength={100}
                    onChange={(e: any) => {
                      setSpeeechText(e.target.value);
                    }}
                  ></textarea>

                  {ttsVendor && selectedTTSModelOption && speechText.length > 4 && (
                    <PrimaryButton
                      handleBtnClick={handlePLaySampleAudio}
                      isDisabled={pauseSamplePlayBtn}
                    >
                      <>
                        <PlayCircle size={32} weight="light" className="me-2" />
                        Play Sample Audio
                      </>
                    </PrimaryButton>
                  )}
                </div>

                <Para className="mb-2">Speech Recognition :</Para>

                <div className="border rounded p-4 mb-4">
                  <SelectInput
                    labelText="Vendor"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.sttModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      if (e == 'Select from options') {
                        setSttModelVendor('');
                      } else {
                        setSttModelModel('');
                        setSttModelVendor(e);
                      }
                    }}
                    // defaultValue={0}
                    required
                  />

                  {sttModelVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor === sttModelVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttModelModel(e);
                      }}
                      defaultValue={0}
                      required
                    />
                  )}

                  {sttModelVendor == 'deepgram' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor === sttModelVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttModelModel(e);
                      }}
                      defaultValue={0}
                      required
                    />
                  )}
                  {sttModelVendor && (
                    <SelectInput
                      labelText="Language"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor == sttModelVendor
                              )?.language
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttLanguage(e);
                      }}
                      defaultValue={0}
                      required
                    />
                  )}
                  <label htmlFor="customRange3" className="form-label">
                    STT endpointing : {sttEndpointing}
                  </label>
                  <input
                    type="range"
                    onChange={(e) => {
                      setSttEndpointing(e.target.value);
                    }}
                    value={sttEndpointing}
                    className="form-range"
                    min="100"
                    max="1500"
                    step="1"
                    id="ssmlConfigSpeedRange"
                    required
                  ></input>
                </div>
                <Para className="mb-2">Conversation Flow :</Para>

                <textarea
                  className="text-area mb-4"
                  rows={10}
                  value={conversationFlow}
                  onChange={(e: any) => {
                    setConversationFlow(e.target.value);
                  }}
                ></textarea>

                <PrimaryTextfield
                  onChange={(value) => setGreetingMessage(value)}
                  label="Greeting Message"
                  placeholder="Enter greeting message"
                  value={greetingMessage}
                  fieldName="greetingMessage"
                  type="text"
                  required
                />

                <TagInput
                  onTagsChange={(value: any[]) => {
                    setEndCallRegex(value);
                  }}
                  label="End call regex"
                  errorMsg={attributesErrorMsg}
                  maxAttributes={100}
                  handleError={(value: string) => setAttributesErrorMsg(value)}
                  handleHint={(value: string) => setHint(value)}
                  hint={hint}
                  alreadyTags={endCallRegex}
                />

                <Para className="mb-2">Extraction Query :</Para>

                <textarea
                  className="text-area mb-4"
                  rows={10}
                  value={extractionQuery}
                  onChange={(e: any) => {
                    setExtractionQuery(e.target.value);
                  }}
                  required
                ></textarea>

                <Para className="mb-2">Instruction :</Para>
                <textarea
                  className="text-area mb-4"
                  rows={10}
                  value={instruction}
                  onChange={(e: any) => {
                    setInstruction(e.target.value);
                  }}
                  required
                ></textarea>

                <Para className="mb-2">Summary Query :</Para>

                <textarea
                  className="text-area mb-4"
                  rows={10}
                  value={summaryQuery}
                  onChange={(e: any) => {
                    setSummaryQuery(e.target.value);
                  }}
                  required
                ></textarea>

                {/* <Para className="mb-2">Translation LLM :</Para>

          <div className="border rounded p-4 mb-4">
            <SelectInput
              labelText="Vendor"
              optionsList={[`microsoft`]}
              setData={(e) => {
                setTranslationLlmVendor(e);
              }}
              defaultValue={0}
              required
            />

            <SelectInput
              labelText="Model"
              optionsList={[`4-turbo`]}
              setData={(e) => {
                setTranslationLlmModel(e);
              }}
              defaultValue={0}
              required
            />

            <SelectInput
              labelText="Region"
              optionsList={[`india`]}
              setData={(e) => {
                setTranslationLlmRegion(e);
              }}
              defaultValue={0}
              required
            />
            <SelectInput
              labelText="Language"
              optionsList={[`en`]}
              setData={(e) => {
                setTranslationLlmLang(e);
              }}
              defaultValue={0}
              required
            />
          </div> */}

                <div className="mb-4">
                  <Para className="mb-2">Type :</Para>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="INCOMING"
                      id="INCOMING"
                      value="INCOMING"
                      onChange={(e: any) => {
                        typeHandler(e);
                      }}
                      checked={type === 'INCOMING'}
                    />
                    <label className="form-check-label">INCOMING</label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="OUTGOING"
                      id="OUTGOING"
                      value="OUTGOING"
                      onChange={(e: any) => {
                        typeHandler(e);
                      }}
                      checked={type === 'OUTGOING'}
                    />
                    <label className="form-check-label">OUTGOING</label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="BOTH"
                      id="BOTH"
                      value="BOTH"
                      onChange={(e: any) => {
                        typeHandler(e);
                      }}
                      checked={type === 'BOTH'}
                    />
                    <label className="form-check-label">BOTH</label>
                  </div>
                </div>

                {type != 'OUTGOING' && (
                  <PrimaryTextfield
                    onChange={(value) => setMobileNumber(value)}
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                    value={mobileNumber}
                    fieldName="mobileNumber"
                    type="text"
                    required
                  />
                )}

                {/* <div className="mb-4">
            <Para>Status : </Para>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="inActive"
                id="true"
                value="true"
                onChange={radioHandler}
                checked={active}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="false"
                name="inActive"
                value="false"
                onChange={radioHandler}
                checked={!active}
              />
              <label className="form-check-label">Inactive</label>
            </div>
          </div> */}

                <PrimaryTextfield
                  onChange={(value) => setDailyLimit(value)}
                  label="Maximum Daily Limit"
                  placeholder="Enter maximum daily limit"
                  value={dailyLimit}
                  fieldName="dailyLimit"
                  type="number"
                  required
                />

                <TagInput
                  onTagsChange={(value: any[]) => {
                    setLanguages(value);
                  }}
                  label="Language"
                  errorMsg={attributesErrorMsg}
                  maxAttributes={100}
                  handleError={(value: string) => setAttributesErrorMsg(value)}
                  handleHint={(value: string) => setHint(value)}
                  hint={hint}
                  alreadyTags={languages}
                />

                <Para className="mb-2">GPT Functions :</Para>
                <textarea
                  className="text-area"
                  rows={10}
                  value={gptFunctions}
                  onChange={(e: any) => {
                    setGptFunctions(e.target.value);
                  }}
                ></textarea>

                <div className="mt-4">
                  <div className="d-flex justify-content-between">
                    <Para className="mb-2">User metadata :</Para>

                    <PrimaryButton handleBtnClick={handleAddField} className="me-2">
                      Add metadata field
                    </PrimaryButton>
                  </div>
                  {showMeta &&
                    metadata.map((field, index) => (
                      <div key={index} className="d-flex my-4 justify-content-between">
                        <input
                          type="text"
                          placeholder="Label"
                          value={field.label}
                          className="form-control primary-input me-2"
                          style={{ width: '40%' }}
                          onChange={(e) => handleInputChange(index, 'label', e.target.value)}
                        />

                        <input
                          type="text"
                          placeholder="Value"
                          value={field.value}
                          className="form-control primary-input me-2"
                          style={{ width: '40%' }}
                          onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                        />

                        <PrimaryButton
                          handleBtnClick={() => {
                            handleRemoveField(index);
                          }}
                        >
                          -
                        </PrimaryButton>
                      </div>
                    ))}
                </div>

                <PrimaryButton
                  className="w-100 mt-4"
                  handleBtnClick={() => {}}
                  isLoading={loadings}
                  type={ButtonType.SUBMIT}
                  // isLoading={isLoading}
                  loadingText="Please wait"
                >
                  Create Campaign
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
}
